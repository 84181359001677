export default [
  {
    path: "/gps-tank-charges",
    name: "gps-tank-charges",
    component: () => import("@/modules/gps-tank-charges/GpsTankCharges.vue"),
    children: [
      {
        path: "list",
        name: "list-gps-tank-charges",
        component: () =>
          import("@/modules/gps-tank-charges/pages/GpsTankChargesList.vue"),
        meta: { permissions: ["gps-tank-charges-show"] }
      }
    ]
  }
];
