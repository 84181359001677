// BG
export const locale = {
  GENERAL: {
    APP_TITLE: "Люник ЕООД"
  },
  TRANSLATOR: {
    SELECT: "Избери своя език"
  },
  MENU: {
    SECTION: {
      SETTINGS: "Настройки",
      PRODUCTS: "Продукти",
      WAREHOUSE: "Склад",
      WAREHOUSE_ITEM: "Артикул на склад"
    },
    ITEM: {
      DASHBOARD: "Начало",
      LIST: "Списък",
      ADD: "Добавяне",
      EDIT: "Редактиране",
      ACTIVE: "Активен",
      STATUS: "Статус",
      ACTIONS: "Опции",
      STATISTICS: {
        STATISTICS: "Статистики",
        SALES_BY_GROUPS: "Продажби по групи",
        SALES_BY_DAYS: "Продажби по дни",
        DELAYED_ORDERS: "Закъснели поръчки"
      },
      ASL: {
        ASL: "Възложители и обекти",
        ASSIGNORS_GROUPS: "Групи възложители",
        GROUP: "Група",
        ASSIGNORS_GROUPS_LIST: "Списък Групи възложители",
        ASSIGNORS: "Възложители",
        ASSIGNOR: "Възложител",
        ASSIGNORS_LIST: "Списък възложители",
        COMPANY: "Фирма",
        LOCATION_NAME: "Име на локацията",
        LOCATION_PLACEHOLDER_NAME: "Мусачево",
        LOCATIONS: "Обекти",
        LOCATION: "Обект",
        NUMBER: "Номер",
        ASSIGNORS_LOCATIONS: "Обекти възложители",
        COMPANY_NAME: "Фирма",
        GAS_STATION_NAME: "Име/бранд на бензиностанцията",
        GAS_STATION_NAME_PLACEHOLDER: "АИМ",
        LOCATION_PLACE_NAME: "Местонахождение",
        LOCATION_PLACE_NAME_PLACEHOLDER: "Драгоман",
        LOCATION_KARIERA: "Кариера/Разтоварище",
        CODE: "Код"
      },
      ASSETS: {
        ASSETS: "Активи",
        STAFF: "Служители",
        MACHINES: "Машини",
        MACHINE: "Машина",
        LIST: "Списък",
        MACHINES_TYPES: "Типове машини",
        MACHINE_TYPE: "Тип машина",
        OWNED_MACHINES: "Собствени машини",
        OWN_MACHINE: "Лична машина",
        RENTED_MACHINES: "Наети машини",
        RENTED_MACHINE: "Наета машина"
      },
      ACTIVITIES: {
        ASSIGNMENTS: "Ангажименти",
        ACTIVITIES: "Дейности",
        BILL_OF_LADING: "Товарителници",
        BILL_OF_LADING_SINGLE: "Товарителница",
        BILL_OF_LADING_NUMBER: "Номер",
        BILL_OF_LADING_DATE: "Дата",
        PROTOCOLS: "Протоколи",
        PROTOCOL: "Протокол",
        BILL_OF_LADING_NOTE: "Забележка",
        BILL_OF_LADING_WORKING_DATE: "За дата"
      },
      PROTOCOLS: {
        PROTOCOLS: "Протоколи",
        PROTOCOL: "Протокол"
      },
      ROLES: {
        ROLES: "Роли"
      },
      USERS: {
        USERS: "Потребители"
      },
      SUBC: {
        SUBCONTRACTORS: "Подизпълнители",
        SUBCONTRACTOR: "Подизпълнител",
        SUBCONTRACTORS_LIST: "Списък подизпълнители",
        SUBCONTRACTORS_TYPES: "Типове подизпълнители",
        NAME: "Име",
        COMPANY_NAME: "Име на фирмата",
        ADDRESS: "Адрес",
        MOL: "МОЛ",
        VAT: "ДДС",
        EIK: "ЕИК",
        EMAIL: "Мейл",
        PHONE: "Телефон",
        SUBCONTRACTOR_TYPE: "Тип",
        SUBCONTRACTORS_LOCATIONS: "Обекти подизпълнители",
        COMPANY: "Фирма",
        LOCATION: "Обект"
      },
      EXP: {
        EXPENSES: "Разходи",
        FUELS: "Горива",
        SUBCONTRACTORS: "Подизпълнители",
        FUELS_EXPENSES: "Разходи горива",
        FUEL_EXPENSE_ROWS: "Разходи горива - редове",
        FUELABLE_TYPE: "Заредил",
        SUB_EXPENSES: "Разходи подизпълнители",
        SUB_EXPENSES_MATERIALS: "Разходи материали",
        INVOICES: "Фактури",
        INCOME_INVOICES: "Приходни фактури",
        SERVICE: "Сервиз"
      },
      INC: {
        INCOME: "Приходи",
        ASSIGNORS: "Възложители"
      },
      REF: {
        REFERENCES: "Справки"
      },
      FUELS: {
        FUELS: "Горива",
        FUELS_TYPES: "Видове горива",
        FUEL_TYPE: "Вид гориво",
        NAME: "Име",
        FUEL_PRICE: "Цена на литър",
        LITERS: "Литри",
        CHARGED_FROM: "Зареждане oт",
        CHARGED_FROM_PLACE: "Място",
        INVOICE_NUMBER: "Фактура",
        DATE_CHARGED: "Дата на зареждане",
        MSM: "Машиносмени",
        MILEAGES: "Километри/моточасове",
        AVG_PRICE: "Средна цена",
        AVAILABLE_LITERS: "Налични литри",
        TITLE: "Зареждане от бидони",
        GPS_TITLE: "GPS - зареждане от бидони",
        EXPENSE: "Разход"
      },
      TANKS: {
        NAME: "Име",
        TANK: "Бидон",
        TANKS: "Бидони",
        GPS_TANKS: "GPS - бидони",
        FORM_LABELS: "Зареждане бидони",
        TANK_CHARGES: "Зареждане бидони",
        CHARGED_FROM: "Зареждане oт",
        VEHICLE_GAS_STATION: "Автомобил/бидон",
        FROM: "От",
        VEHICLE: "Автомобил",
        TANK_CAPACITY: "Вместимост/обем литри",
        CAPACITY: "Вместимост",
        TANK_CAPACITY_PLACEHOLDER: "1150",
        GAS_STATION: "Бензиностанция",
        CONFIRM_CHARGES: "Потвърди зареждания",
        SYNC: "Синхронизиране",
        MARK_AT_LEAST_ONE_CHARGE: "Моля маркираите поне едно зареждане от списъка",
        CONFIRM_TRANSFER: "Желаете ли да прехвърлите {value} зарежданe/ия",
      },
      SRV: {
        SERVICES: "Услуги",
        SERVICE: "Услуга",
        SERVICE_NAME: "Име на услугата",
        QTY: "Брой"
      },
      MEASURES: {
        MEASURES: "Мерки",
        MEASURE: "Мярка"
      },
      SETTINGS: {
        SETTINGS: "Настройки",
        USERS: "Потребители",
        STAFF: "Служители",
        LOCATIONS: "Локации",
        GENERAL: "Други настройки",
        ROLES: "Роли",
        STAFF_VEHICLE: "Лични автомобили",
        GAS_STATIONS: "Бензиностанции",
        GAS_STATION_NAME: "Име на бензиностанция"
      },
      STAFF: {
        PLACEHOLDER_HOURLY_RATE: "10.90",
        FIRST_NAME: "Име",
        LAST_NAME: "Фамилия",
        HOURLY_RATE: "Часова ставка",
        STAFF_VEHICLE: "Лични автомобили",
        REGISTRATION_PLATE: "Регистрационнен номер",
        STAFF: "Служител",
        STAFF_NAMES: "Служител име фамилия"
      },
      ITEMS: {
        ALL: "Продукти/Съставки",
        PRODUCTS: "Продукти",
        INGREDIENTS: "Съставки",
        ITEM_CATEGORIES: "Категории",
        RECIPES: "Рецепти",
        PRICES: "Цени",
        PROMOTIONS: "Промоции"
      },
      SERVICE: {
        TYPES: "Дейности и такси",
        WAREHOUSES: "Складове",
        WAREHOUSE_ITEMS: "Артикули на склад",
        VENDORS: "Доставчици",
        MACHINES: "Обслужване и разходи",
        MACHINE_SERVICE: "Обслужване",
        MACHINE_EXPENCE: "Планови разходи",
        INVOICES: "Фактури",
        MACHINE_SERVICES: "Обслужвание и разходи за {machine}"
      }
    }
  },
  BUTTON: {
    CHECKBOXES: "Отметки",
    NEW: "Нов",
    SEND: "Изпрати",
    READY: "Готово",
    BACK: "Назад",
    ADD: "Добави",
    ADD_NEW: "Добави нов",
    DELETE: "Изтрий",
    SAVE: "Запази",
    COPY: "Копирай",
    DUPLICATE: "Дублирай",
    SAVE_AND_CONTINUE: "Запази и продължи",
    SAVE_AND_COPY: "Запази и копирай",
    SAVE_AND_ADD_NEW: "Запази и добави нов",
    SAVE_AND_EXIT: "Запази и излез",
    SET_NEW_PASSWORD: "Промени Паролата",
    NO_NEW_PASSWORD: "Не Променяй Паролата",
    GENERATE_PASSWORD: "Генерирай Парола",
    GENERATE: "Генерирай",
    CONTINUE: "Продължи",
    UPLOAD: "Качи",
    REFRESH: "Презареди",
    FILES: "Файлове",
    DOWNLOAD: "Свали",
    CLOSE: "Затвори",
    CONFIRM: "Потвърди",
    ALL: "Всички",
    APPLY: "Приложи",
    APPLY_FOR_ALL_STORES: "Приложи за всички магазини",
    CREATE: "Създай",
    PLEASE_CONFIRM: "Моля потвърдете",
    ACTIONS: "Действия",
    FINALIZE: "Финализирай",
    PREVIEW: "Преглед",
    PRINT: "Разпечатай",
    EXCEL: "Ексел",
    ADD_INVOICE: "Фактурирай",
    PDF: "PDF",
    PDFPRINT: "PDF/PRINT",
    SEND_ASSIGNMENT_NOTIFICATIONS: "Изпрати график по Viber",
    TEST_ERRORS: "Тест грешки",
    TRANSFER: "Прехвърли"
  },
  TABLE: {
    ITEMS_PER_PAGE_TEXT: "Резултати на страница"
  },
  AUTH: {
    GENERAL: {
      FOOTER_LEFT: "Люник ЕООД",
      WELCOME: "Люник ЕООД",
      WELCOME_DESCRIPTION: "Платформа за управление",
      SIGNUP_BUTTON: "Вход",
      BACK_BUTTON: "Назад",
      PRIVACY: "Политика за поверителност"
    },
    LOGIN: {
      TITLE: "Вход",
      DESCRIPTION:
        "Моля свържете се с администратор за да получите потребителско име и парола",
      VALID_EMAIL: "Въведете валиден имейл.",
      VALID_PASSWORD: "Паролата е задължителна.",
      VALID_PASSWORD_CONFIRM: "Паролите не съвпадат.",
      BUTTON: "Вход",
      PRIVACY: "",
      LEGAL: "",
      CONTACT: ""
    },
    TWO_FACTOR: {
      TITLE: "2FA",
      DESCRIPTION:
        "За да продължите, въведете 6 цифрения код изпратен на вашия имейл!",
      CODE: "Код",
      VALID_CODE: "Въведете валиден код."
    },
    FORGOT: {
      TITLE: "Забравена парола?",
      SUCCESS: "Ще получите имейл с линк за рестартиране на паролата си.",
      DESC: "Въведете вашия имейл, за да рестартирате паролата си!"
    },
    RESET: {
      TITLE: "Въведете вашата нова парола",
      DESC: "Въведете вашия имейл, за да рестартирате паролата си!",
      SUCCESS: "Вашата парола беше успешно рестартирана."
    },
    REGISTER: {
      TITLE: "Регистрация",
      DESC: "Въведете вашите данни, за да създадете акаунт",
      SUCCESS: "Вашият акаунт е създаден успешно."
    },
    INPUT: {
      EMAIL: "Имейл",
      FULLNAME: "Имена",
      PASSWORD: "Парола",
      CONFIRM_PASSWORD: "Потвърдете парола",
      USERNAME: "Потребителско име"
    }
  },
  VALIDATION: {
    VALID: "",
    INVALID: "Полето {name} е невалидно",
    REQUIRED: "Полето {name} е задължително",
    LENGTH: "Полето {name} трябва да е {length} символа",
    MIN_LENGTH: "Полето {name} трябва да е минимум {min} символа",
    AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
    NOT_FOUND: "The requested {name} is not found",
    INVALID_LOGIN: "The login detail is incorrect",
    REQUIRED_FIELD: "Required field",
    MIN_LENGTH_FIELD: "Minimum field length:",
    MAX_LENGTH_FIELD: "Maximum field length:",
    INVALID_FIELD: "Field is not valid",
    INVALID_FILE: "Файла не е валиден",
    NO_RESULTS: "Няма намерени резултати.",
    FILE_BIG_SIZE: "Файла е твърде голям. Позволена големина: {value}",
    FILE_TYPE: "Непозволен тип на файл. Позволени типове: {value}",
  },
  PROFILE: {
    TITLE: "Потребителски Профил",
    HI: "Здравей",
    LOGOUT: "Изход",
    MY_PROFILE: "Моя профил",
    PROFILE_SETTINGS: "Настройки на профила"
  },
  FORM: {
    VALID_EMAIL: "Въведете валиден имейл.",
    VALID_NAME: "Полето е невалидно.",
    VALID_TYPE: "Въведете валиден тип.",
    VALID_MACHINE_MODEL: "Въведете валиден модел/марка.",
    VALID_FIRST_NAME: "Въведете валидно име.",
    VALID_LAST_NAME: "Въведете валиднa фамилия.",
    VALID_ACTIVE: "Въведете валиднa стойност за активен:",
    VALID_NO_MILEAGE: "Въведете валиднa стойност за без километраж/моточасове:",
    VALID_ROLE: "Въведете валиднa роля:",
    VALID_IS_ASSIGNABLE: "Да се показва в календар:",
    VALID_PHONE: "Телефоният номер трябва да е точно 10 цифри и да започва с 0"
  },
  FORM_LABELS: {
    GROUP: "Група",
    EMAIL: "Email адрес:",
    NAME: "Име",
    FIRST_NAME: "Име:",
    LAST_NAME: "Фамилия:",
    ACTIVE: "Активен:",
    NO_MILEAGE: "Без километраж/моточасове:",
    ROLE: "Роля:",
    ROLES: "Роли:",
    PERMISSIONS: "Права:",
    LAT: "Latitude:",
    LNG: "Longitude:",
    LOCATION: "Местоположение",
    ADDRESS: "Адрес",
    DESCRIPTION: "Описание:",
    NOTES: "Бележки:",
    SEARCH_PLACE: "Търси място:",
    CITY: "Град:",
    FROM: "От",
    TO: "До",
    SLUG: "Slug:",
    CLOCK_CODE: "Clock Code:",
    PASS_CODE: "Pass Code:",
    HOURLY_RATE: "Hourly Rate:",
    LANG: "Език:",
    PASSWORD: "Парола:",
    SELECT_ALL: "Избери всички",
    NO_SELECTED: "Няма избрани",
    SELECTED: "Избрани:",
    ALL_SELECTED: "Всички са избрани",
    DEFAULT_COST: "Стойност:",
    PRICE: "Цена",
    DIFFERENT_PRICE: "Различна цена?",
    UNIT_VALUE: "Мерна единица стойност:",
    UNIT: "Мерна единица:",
    SHORTCODE: "Съкращение:",
    SORT: "Подредба:",
    TITLE: "Заглавие:",
    ICON: "Иконка:",
    IMG: "Снимка:",
    VALID_RANGE: "Дата от - до:",
    VALUE: "Стойност",
    VALUE_NO_VAT: "Стойност без ДДС",
    VALUE_WITH_VAT: "Стойност с ДДС",
    VALUE_VAT: "Стойност  ДДС",
    VALUE_FROM_PROTOCOLS: "Стойност протоколи",
    VAT: "ДДС",
    USERS: "Потребители",
    USER_GROUPS: "Потребителски Групи",
    CUSTOMERS: "Клиенти",
    CUSTOMER_GROUPS: "Клиентски Групи",
    NUMBER_OF_CODES: "Брой кодове:",
    CREATED_AT: "Създаден на",
    UPDATE_AT: "Обновен на",
    IMAGE: "Снимка",
    MACHINE_TYPE: "Тип машина",
    MACHINES_TYPES: "Типове машини",
    MODEL: "Модел",
    REGISTRATION_PLATE: "Рег. номер",
    MACHINE_FUEL_TYPE: "Гориво",
    OWNERSHIP: "Собственост",
    TANK_SIZE: "Обем на резервоара",
    GPS_TRACKER_NUMBER: "GPS номер",
    SUBCONTRACTOR: "Фирма",
    MOTOR_SHIFT_HOURS: "Часовете за една мотосмяна",
    MSM_HOURS: "Часовете за една мотосмяна",
    MSM_PRICE: "Цена на мотосмяна",
    OWNED_MACHINE: "Собствена",
    COMPANY: "Фирма",
    COMPANY_TYPE: "Тип фирма",
    COMPANY_NAME: "Име на фирмата",
    HAS_LOCATION: "Налична локация",
    TANK_NAME: "Име на Бидона",
    TANK_NAME_PLACEHOLDER: "Бидон 1000",
    TANK_LOCATION_PLACEHOLDER: "Расово",
    AVG_PRICE: "Средна цена",
    AVAILABLE_LITERS: "Налични литри",
    CAPACITY: "Вместимост",
    MACHINE: "Машина",
    SERVICE_NAME: "Име на услугата",
    SERVICE_NAME_PLACEHOLDER: "услуга с механизация - водоноска",
    MEASURE_NAME: "Мярка",
    MEASURE_NAME_PLACEHOLDER: "Тон/Курс",
    NOTE: "Забележка",
    QTY: "К-во",
    UNIT_PRICE: "Ед. цена",
    UNIT_TOTAL_PRICE: "Обща сума",
    TOTAL_PRICE: "Сума",
    INVOICE_NUMBER: "Номер на фактура",
    INVOICE_DATE: "Дата на фактура",
    FROM_DATE: "От Дата",
    TO_DATE: "До Дата",
    DETAILS_NAME: "Наименование",
    DATE: "Дата",
    COMPLETED: "Завършен",
    PROTOCOL_NAME: "Номер на протокол",
    PROTOCOL: "Протокол",
    NO_PROTOCOL: "Без протокол",
    NO_ASSIGNOR: "Без възложител",
    ASSIGNOR: "Възложител",
    LOCATION: "Обект",
    NO_LOCATION: "Без обект",
    DATE_FROM: "Дата от",
    DATE_TO: "Дата до",
    FINISHED_FILTER: "Финализирани",
    INVOICE_FILTER: "Фактурирани",
    SHOW_UNLOADING: "Покажи кариера/разтоварище на справката",
    NOT_FINISHED: "Не е финализиран",
    NO_INVOICE: "Не е фактуриран",
    UNLOADING: "Кариера/разтоварище",
    WORK_DATE: "Дата",
    WORK_QTY: "Брой",
    UNLOADING_TYPE: "Суровина/материал",
    SERVICE: "Услуга",
    TOTAL: "Общо",
    TYPE: "Вид",
    SEARCH_BY_QTY: "Търсене по количество",
    MACHINE_MODEL: "Модел/марка",
    BILL_NUMBER: "Т-ца",
    IS_UNLOADING: "С кариера/разтоварище",
    ADD_NO_INVOICE: "Без фактура",
    QTY: "Количество",
    IS_MILEAGE: "Тип работа",
    HAS_ASSIGNMENTS_FILTER: "С/без ангажименти",
    IS_ASSIGNABLE: "Да се показва в календар",
    PHONE: "Телефонен номер",
    PHONE_SHORT: "Тел.",
    REAL_NAME: "Име на файл",
    FILE_SIZE: "Размер",
    CREATED_AT: "Добавен на",
    FILE: "Файл",
    PERIOD_VALUE: "Километри/моточасове",
    PERIOD_KM: "Километри",
    PERIOD_HOURS: "Моточасове",
    PERIOD_TIME: "Време",
    MONTHS: "Месеци",
    DAYS: "Дни",
    AVAILABLE: "Наличност",
    CAPACITY_QTY: "Капацитет",
    BULSTAT: "Булстат",
    SERVICE_TYPES: "Дейности",
    SERVICE_TYPE: "Дейност/такса",
    VENDOR: "Доставчик",
    WAREHOUSE: "Склад",
    WAREHOUSE_ITEM: "Артикул на склад",
    UPCOMING_SERVICE: "Предстоящо обслужване/задължение",
    EXPENSES: "Разходи",
    CODE: "Код",
    SERVICE_GROUP: "Група",
    MAX_CAPACITY: "Максимален капацитет",
    FIRM_NAME: "Име на фирмата",
    TANK: "Бидон",
  },
  FORM_PLACEHOLDERS: {
    EMAIL: "Email адрес",
    NAME: "Име",
    FIRST_NAME: "Име",
    LAST_NAME: "Фамилия",
    ACTIVE: "Активен",
    ROLE: "Роля",
    LAT: "Latitude",
    LNG: "Longitude",
    LOCATION: "Местоположение",
    ADDRESS: "Адрес",
    DESCRIPTION: "Описание",
    DESCRIPTION_MAX_140: "Описание (максимум 140 символа)",
    NOTES: "Бележки",
    CITY: "Град",
    SLUG: "Slug",
    CLOCK_CODE: "Clock Code",
    PASS_CODE: "Pass Code",
    HOURLY_RATE: "Hourly Rate",
    LANG: "Език",
    PASSWORD: "Парола",
    FOR_BRAND: "За Бранд",
    ITEM_CATEGORY_TYPE: "Тип категория",
    ITEM_RECIPE_ADDON: "Добавка към рецепта",
    SORT: "Подредба",
    TITLE: "Заглавие",
    ICON: "Иконка",
    IMG: "Снимка",
    MACHINE_TYPE: "4 оси, 30 тона, и т.н.",
    MACHINE_MODEL: "Модел/марка",
    MACHINE_REGISTRATION_PLATE: "Регистрационнен номер",
    MACHINE_REGISTRATION_PLATE_SHORT: "CA7878CB",
    TANK_SIZE: "в литри",
    GPS_TRACKER_NUMBER: "ID от системата",
    PROTOCOL_NAME: "17",
    STAFF_NAME: "Служител",
    TYPE: "Вид",
    MACHINE: "Машина",
    MACHINES: "Машина/и",
    IS_UNLOADING: "С кариера/разтоварище",
    NO_MILEAGE: "Без километраж/моточасове",
    IS_ASSIGNABLE: "Да се показва в календар",
    DATES: "Дати",
    PHONE: "Пример: 0881234567",
    PERIOD_TYPE: "Тип на периодичност"
  },

  LABELS: {
    LIST: "Списък",
    ADDRESS: "Адрес",
    ADD_NEW: "Добавяне",
    EXPORT: "PDF",
    EDIT: "Редактиране",
    CLEAR: "Изчистване",
    SEARCH: "Търсене",
    ADD_POLYGON: "Добави полигон",
    RESET_POLYGON: "Изчисти полигон",
    RECENTER_MAP: "Центрирай картата",
    STEP: "Стъпка",
    VIEW: "Преглед",
    SHOW_BILLS_OF_LADING: "Покажи товарителници",
    PRINT: "Разпечатай",
    SEARCH_BOL:
      "Търсене по номер, услуга, регистрационен номер, протокол, машина",
    SHOW_BILLS_OF_LADING_WORK: "Покажи дейности към товарителници",
    FILES: "Файлове",
    DOWNLOAD: "Изтегляне",
    PERIOD_TYPE_HOURS: "Километри",
    PERIOD_TYPE_KM: "Километри",
    PERIOD_TYPE_HOURS: "Моточасове",
    PERIOD_TYPE_KMORTIME: "Километри или време",
    PERIOD_TYPE_HOURSORTIME: "Моточасове или време",
    PERIOD_TYPE_TIME: "Време",
    PERIOD_TYPE_NONE: "Без периодичност",
    YEAR: "Година",
    WEEK: "Седмица",
    DAY: "Ден",
    DELETE: "Изтриване",
  },
  WEEK_DAYS: {
    MONDAY: "ПОНЕДЕЛНИК",
    TUESDAY: "ВТОРНИК",
    WEDNESDAY: "СРЯДА",
    THURSDAY: "ЧЕТВЪРТЪК",
    FRIDAY: "ПЕТЪК",
    SATURDAY: "СЪБОТА",
    SUNDAY: "НЕДЕЛЯ",
    DAYS: "ДНИ"
  },
  ALERTS: {
    SUCCESS: "Успех",
    ERROR: "Грешка",
    NO_DATA: "Няма данни",
    WARNING: "Вниемание",
    BILL_NUMBER_EXIST: "Вече съществува товарителница с този номер!",
    SUCCESSFUL_DELETE: "Успешно изтриване",
  },
  ASSIGNMENTS: {
    MACHINE: "Машина",
    DAYS: {
      mon: "Пон",
      tue: "Вто",
      wed: "Сря",
      thu: "Чет",
      fri: "Пет",
      sat: "Съб",
      sun: "Нед"
    },
    TYPE: "Тип",
    DATE: "Дата",
    START_TIME: "Начален час",
    END_TIME: "Краен час",
    IS_FULL_DAY: "Целодневен ангажимент",
    DESCRIPTION: "Описание",
    CONFIRM_RESEND_NOTIFICATIONS:
      "Желаете ли да изпратите отново седмичния график на служителя по Viber?"
  },
  ACTION_STATUS: {
    IS_SET: "Действието предстои да започне.",
    IS_RUNNING: "Действието все още е в процес на извършване.",
    IS_FINISHED: "Действието е извършено успешно.",
    IS_FAILED: "Действието е извършено неуспешно.",
    IS_CANCELLED: "Действието е прекратено.",
    REFRESH_STATUS:
      "За да проследите дали статуса се е сменил, моля презаредете."
  },
  PERMISSIONS: {
    "assignors-groups-show": "Групи възложители - списък",
    "assignors-groups-create": "Групи възложители - добавяне",
    "assignors-groups-update": "Групи възложители - обновяване",
    "assignors-groups-delete": "Групи възложители - изтриване",
    "assignors-show": "Възложители - списък",
    "assignors-create": "Възложители - добавяне",
    "assignors-update": "Възложители - обновяване",
    "assignors-delete": "Възложители - изтриване",
    "assignor-locations-show": "Обекти възложители - списък",
    "assignor-locations-create": "Обекти възложители - добавяне",
    "assignor-locations-update": "Обекти възложители - обновяване",
    "assignor-locations-delete": "Обекти възложители - изтриване",
    "subcontractors-show": "Подизпълнители - списък",
    "subcontractors-create": "Подизпълнители - добавяне",
    "subcontractors-update": "Подизпълнители - обновяване",
    "subcontractors-delete": "Подизпълнители - изтриване",
    "subcontractor-locations-show": "Обекти подизпълнители - списък",
    "subcontractor-locations-create": "Обекти подизпълнители - добавяне",
    "subcontractor-locations-update": "Обекти подизпълнители - обновяване",
    "subcontractor-locations-delete": "Обекти подизпълнители - изтриване",
    "gas-stations-show": "Бензиностанции - списък",
    "gas-stations-create": "Бензиностанции - добавяне",
    "gas-stations-update": "Бензиностанции - обновяване",
    "gas-stations-delete": "Бензиностанции - изтриване",
    "staff-show": "Служители - списък",
    "staff-create": "Служители - добавяне",
    "staff-update": "Служители - обновяване",
    "staff-delete": "Служители - изтриване",
    "staff-vehicle-show": "Лични автомобили - списък",
    "staff-vehicle-create": "Лични автомобили - добавяне",
    "staff-vehicle-update": "Лични автомобили - обновяване",
    "staff-vehicle-delete": "Лични автомобили - изтриване",
    "machines-types-show": "Типове машини - списък",
    "machines-types-create": "Типове машини - добавяне",
    "machines-types-update": "Типове машини - обновяване",
    "machines-types-delete": "Типове машини - изтриване",
    "machines-show": "Собствени машини - списък",
    "machines-create": "Собствени машини - добавяне",
    "machines-update": "Собствени машини - обновяване",
    "machines-delete": "Собствени машини - изтриване",
    "rented-machines-show": "Наети машини - списък",
    "rented-machines-create": "Наети машини - добавяне",
    "rented-machines-update": "Наети машини - обновяване",
    "rented-machines-delete": "Наети машини - изтриване",
    "tank-charges-show": "Зареждане от бидони - списък",
    "tank-charges-create": "Зареждане от бидони - добавяне",
    "tank-charges-update": "Зареждане от бидони - обновяване",
    "tank-charges-delete": "Зареждане от бидони - изтриване",
    "bill-of-lading-show": "Товарителници - списък",
    "bill-of-lading-create": "Товарителници - добавяне",
    "bill-of-lading-update": "Товарителници - обновяване",
    "bill-of-lading-delete": "Товарителници - изтриване",
    "protocols-show": "Протоколи - списък",
    "protocols-create": "Протоколи - добавяне",
    "protocols-update": "Протоколи - обновяване",
    "protocols-delete": "Протоколи - изтриване",
    "protocols-finalize": "Протоколи - финализиране",
    "fuel-expenses-show": "Разходи горива - списък",
    "fuel-expenses-create": "Разходи горива - добавяне",
    "fuel-expenses-update": "Разходи горива - обновяване",
    "fuel-expenses-delete": "Разходи горива - изтриване",
    "subcontractors-invoices-show": "Разходи подизпълнители - списък",
    "subcontractors-invoices-create": "Разходи подизпълнители - добавяне",
    "subcontractors-invoices-update": "Разходи подизпълнители - обновяване",
    "subcontractors-invoices-delete": "Разходи подизпълнители - изтриване",
    "subcontractors-invoices-materials-show": "Разходи материали - списък",
    "subcontractors-invoices-materials-create": "Разходи материали - добавяне",
    "subcontractors-invoices-materials-update":
      "Разходи материали - обновяване",
    "subcontractors-invoices-materials-delete": "Разходи материали - изтриване",
    "services-show": "Услуги - списък",
    "services-create": "Услуги - добавяне",
    "services-update": "Услуги - обновяване",
    "services-delete": "Услуги - изтриване",
    "measures-show": "Мерки - списък",
    "measures-create": "Мерки - добавяне",
    "measures-update": "Мерки - обновяване",
    "measures-delete": "Мерки - изтриване",
    "fuels-types-show": "Видове горива - списък",
    "fuels-types-create": "Видове горива - добавяне",
    "fuels-types-update": "Видове горива - обновяване",
    "fuels-types-delete": "Видове горива - изтриване",
    "subcontractors-types-show": "Типове подизпълнители - списък",
    "subcontractors-types-create": "Типове подизпълнители - добавяне",
    "subcontractors-types-update": "Типове подизпълнители - обновяване",
    "subcontractors-types-delete": "Типове подизпълнители - изтриване",
    "tanks-show": "Бидони - списък",
    "tanks-create": "Бидони - добавяне",
    "tanks-update": "Бидони - обновяване",
    "tanks-delete": "Бидони - изтриване",
    "users-show": "Потребители - списък",
    "users-create": "Потребители - добавяне",
    "users-update": "Потребители - обновяване",
    "users-delete": "Потребители - изтриване",
    "roles-show": "Роли - списък",
    "roles-create": "Роли - добавяне",
    "roles-update": "Роли - обновяване",
    "roles-delete": "Роли - изтриване",
    "statistics-show": "Статистики",
    "assignments-show": "Ангажименти - списък",
    "assignments-create": "Ангажименти - добавяне",
    "assignments-update": "Ангажименти - обновяване",
    "assignments-delete": "Ангажименти - изтриване",
    "service-show": "Сервиз - списък",
    "service-create": "Сервиз - добавяне",
    "service-update": "Сервиз - обновяване",
    "service-delete": "Сервиз - изтриване",
    "gps-tank-charges-show": "GPS - зареждане от бидони - списък",
    "gps-tank-charges-transfer": "GPS - зареждане от бидони - потвърждение",
    "gps-tank-charges-delete": "GPS - зареждане от бидони - изтриване",
    "gps-tanks-show": "GPS - бидони - списък",
  }
};
