export default [
  {
    path: "/gps-tanks",
    name: "gps-tank",
    component: () => import("@/modules/gps-tanks/GpsTanks.vue"),
    children: [
      {
        path: "list",
        name: "list-gps-tanks",
        component: () => import("@/modules/gps-tanks/pages/GpsTanksList.vue"),
        meta: { permissions: ["gps-tanks-show"] }
      }
    ]
  }
];
