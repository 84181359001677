import ApiService from "@/core/services/api.service";

//Actions
export const FETCH_GPS_TANK_CHARGES = "fetchGpsTankCharges";
export const TRANSFER_GPS_TANK_CHARGES = "transferGpsTankCharges";
export const DELETE_GPS_TANK_CHARGES = "deleteGpsTankCharges";
export const SYNC_YESTERDAY_GPS_TANK_CHARGES = "syncYesterdayGpsTankCharges";

//Mutations
export const SET_LOADING = "setLoadingStaffVehicle";

const state = {
  isLoadingGpsTankCharge: false,
};

const getters = {
  isLoadingGpsTankCharge() {
    return state.isLoadingGpsTankCharge;
  },
};

const actions = {
  [FETCH_GPS_TANK_CHARGES](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("gps-tank-charges" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [TRANSFER_GPS_TANK_CHARGES](context, selectedGpsTankCharges){    
    context.commit(SET_LOADING, true);

    selectedGpsTankCharges = JSON.parse(JSON.stringify(selectedGpsTankCharges));

    return new Promise((resolve, reject) => {
      ApiService.post("transfer-gps-tank-charges", selectedGpsTankCharges)
        .then(({ data }) => {          
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_GPS_TANK_CHARGES](context, id){    
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("gps-tank-charges/" + id)
        .then(({ data }) => {          
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    }); 
  },
  [SYNC_YESTERDAY_GPS_TANK_CHARGES](context){    
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("gps-tank-charges-sync-yesterday")
        .then(({ data }) => {          
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    }); 
  },
};

const mutations = {
  [SET_LOADING](state, value) {
    state.isLoadingGpsTankCharge = value;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
